export const DateUtils = {
    formatDateTime: (datetimeString) => {
        const date = new Date(datetimeString);
        return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZone: 'UTC'
        }).format(date);
    }
};
