import React, { useEffect, useState } from 'react';
import FormData from 'form-data';
import { ADD_STORY, AVAILABLE_ROLES, MAX_FILE_SIZE_MB, TOKEN_KEY } from '../config/Constants';
import { axiosInstance, setAuthToken } from './AxiosInstance';
import { useNavigate } from 'react-router-dom';

const PostStory = ({setErrorMsg}) => {
  const [heading, setHeading] = useState('');
  const [file, setFile] = useState(null);
  const [assetUrl, setAssetUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [fileError, setFileError] = useState(null);
  const [selectedRole, setSelectedRole] = useState('');
  const [availableRoles, setAvailableRoles] = useState(null);
  const navigate = useNavigate();

  const handleHeadingChange = (event) => {
    setHeading(event.target.value);
  };

  useEffect(() => {
    setAuthToken(localStorage.getItem(TOKEN_KEY));
    axiosInstance.get(AVAILABLE_ROLES)
      .then((resp) => {
        setAvailableRoles(resp.data);
      }).catch((error) => setErrorMsg("Error getting available roles options"));
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Access the File object
    if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
      setFileError('File size exceeds ' +MAX_FILE_SIZE_MB +' MB limit.');
      setFile(null);
      return;
    }
    setFile(file);
};

  const handleAssetUrlChange = (event) => {
    setAssetUrl(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMsg(null);

    const formData = new FormData();
    formData.append('heading', heading);
    formData.append('storyAccessor', selectedRole);
    if (file) {
      formData.append('file', file);
    }
    formData.append('assetUrl', assetUrl);

    try {
      setAuthToken(localStorage.getItem(TOKEN_KEY));
      const response = await axiosInstance.post(ADD_STORY, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      navigate("/admin/");
    } catch (error) {
      setErrorMsg(error.message || 'An error occurred.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="page-container display-grid" >
      <div className="max-content" >
      <h3 className='mx-2 my-2'>Add Story</h3>
      <form onSubmit={handleSubmit}>
        <div className="justify-content-between">
            <div className="form-group mx-2 my-2">
                <label htmlFor="heading">Heading</label>
                <input
                    type="text"
                    className="form-control"
                    id="heading"
                    value={heading}
                    onChange={handleHeadingChange}
                    required
                />
            </div>
            <div className="form-group mx-2 my-2">
                <label htmlFor="assetUrl">Asset URL (Optional)</label>
                <input
                    type="text"
                    className="form-control"
                    id="assetUrl"
                    value={assetUrl}
                    onChange={handleAssetUrlChange}
                />
            </div>
        </div>
        <div className='d-flex'>
          <div className="form-group mx-2 my-2">
          <label htmlFor="file">Chose which users to be allowed access</label>
            <select value={selectedRole} onChange={(e) => {setSelectedRole(e.target.value)}} className="form-control" required>
              <option value="" disabled>Roles</option>
              {availableRoles &&
                availableRoles.map((role) => <option key={role} value={role}>{role}</option>)
              }
            </select>
          </div>
          <div className="form-group mx-2 my-2">
                <label htmlFor="file">Image (Max {MAX_FILE_SIZE_MB} MB)</label>
                <input
                    type="file"
                    className="form-control"
                    id="file"
                    accept="image/*"
                    onChange={handleFileChange}
                />
                {fileError && <p className="text-danger">{fileError}</p>}
          </div>
        </div>
        <button type="submit" className="btn btn-primary mx-2" disabled={isLoading}>
          {isLoading ? 'Posting...' : 'Post Story'}
        </button>
      </form>
    </div>
    </div>
  );
};

export default PostStory;