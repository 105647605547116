export const BASE_URL = "https://wife-adventures.club/api/v1/";
// export const BASE_URL = "http://localhost:8080/api/v1/";

// OPEN to PUBLIC
export const LOGIN_API = BASE_URL + "login";
export const REGISTER_API = BASE_URL + "register";
export const LOGOUT_API = BASE_URL + "logout";
export const IS_SERVER_UP = BASE_URL + "serverStatus";
export const AVAILABLE_ROLES = BASE_URL + "availableRoles";
export const GET_WEBSITE_LOGO_API = BASE_URL + "getWebsiteLogo";
export const GET_WEBSITE_BACKGROUND_API = BASE_URL + "getWebsiteBackground"

// For USERS
export const CURRENT_USER = BASE_URL + "user";
export const STORY_PAGE = BASE_URL + "story";
export const CONTROLLER_APP_PAGE = BASE_URL + "controllerApp";
export const ACTIVATE_REGISTERED_USER = BASE_URL + "activateRegisteredUser";
export const RESEND_OTP_API = BASE_URL + "resendOtp";
export const SEND_OTP_PASSWORD_RESET_API = BASE_URL + "sendOtpForPasswordReset";
export const CHANGE_PASSWORD_API = BASE_URL + "changePassword";
export const STORY_IMAGE_API = BASE_URL + "story/getImage";
export const STORY_IMAGE_BYTES_API = BASE_URL + "story/getImageBytes?imageId=1";
export const STORY_IMAGE_OBJECT_API = BASE_URL + "story/getImageObject";

// ADMIN PAGES
export const ALL_USERS = BASE_URL + "admin/allUsers";
export const IS_ADMIN_API = BASE_URL + "admin";
export const ADD_STORY = BASE_URL + "admin/addStory";
export const ADD_IMAGE = BASE_URL + "admin/addImage";
export const EDIT_STORY_API = BASE_URL + "admin/editStory";
export const ADD_IMAGE_TO_STORY = BASE_URL + "admin/addImageIdToStory";
export const REMOVE_IMAGE_TO_STORY = BASE_URL + "admin/removeImageIdToStory";
export const ALL_STORIES = BASE_URL + "admin/allStories";
export const DELETE_STORY_API = BASE_URL + "admin/deleteStory";
export const DELETE_IMAGE_API = BASE_URL + "admin/deleteImage";
export const ALL_IMAGES_API = BASE_URL + "admin/allImages";
export const PATRON_INFO_API = BASE_URL + "admin/getPatronDetails";
export const UPDATE_PATRON_INFO_AUTO_API = BASE_URL + "admin/updatePatronToken";
export const BLOCK_USER_API = BASE_URL + "admin/blockUser";
export const UNBLOCK_USER_API = BASE_URL + "admin/unblockUser";
export const SET_USER_ROLE = BASE_URL + "admin/setUserRole";
export const UPDATE_USER_ROLE_FROM_PATREON = BASE_URL + "admin/updateUserRoles";
export const UPDATE_STORY_ACCESSOR = BASE_URL + "admin/updateStoryAccessor";
export const GET_MISC_DATA = BASE_URL + "admin/getMiscData";
export const UPDATE_MISC_DATA = BASE_URL + "admin/updateMiscData";

export const TOKEN_KEY = "token-gaming-service";

export const CHECK_TOKEN_EXIST_INTERVAL = 3000;
export const MAX_FILE_SIZE_MB = 100;