import React, { useEffect, useState } from 'react';
import { GET_MISC_DATA, MAX_FILE_SIZE_MB, TOKEN_KEY, UPDATE_MISC_DATA } from '../config/Constants';
import { axiosInstance, setAuthToken } from './AxiosInstance';
import { Col, Container, Row } from 'react-bootstrap';
import Loading from './Loading';

const Miscellaneous = ({setErrorMsg}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [dollarValueTier1, setDollarValueTier1] = useState(null);
    const [dollarValueTier2, setDollarValueTier2] = useState(null);
    setAuthToken(localStorage.getItem(TOKEN_KEY));

    const fetchData = async () => {
        setIsLoading(true);
        try{
            const miscData = await axiosInstance.get(GET_MISC_DATA);
            setDollarValueTier1(miscData.data.paidMember);
            setDollarValueTier2(miscData.data.paidMemberPlus);
        } catch (error){
            setErrorMsg("Failed to get misc data");
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const updateDollarValueTier1 = async () => {
        try{
            const miscData = await axiosInstance.patch(UPDATE_MISC_DATA, {key: 'paidMember', value: dollarValueTier1});
            fetchData();
        } catch (error){
            setErrorMsg("Failed to update dollar value for paid member");
        }
    }

    const handleDollarTier1Change = (value) => {
        if(value < 0){
            setErrorMsg("Dollar value cannot be less than Zero")
            return;
        }
        setDollarValueTier1(value);
    }

    const updateDollarValueTier2 = async () => {
        try{
            const miscData = await axiosInstance.patch(UPDATE_MISC_DATA, {key: 'paidMemberPlus', value: dollarValueTier2});
            fetchData();
        } catch (error){
            setErrorMsg("Failed to update dollar value for paid member");
        }
    }

    const handleDollarTier2Change = (value) => {
        if(value < 0){
            setErrorMsg("Dollar value cannot be less than Zero")
            return;
        }
        setDollarValueTier2(value);
    }

    if(isLoading === true) return <Loading />;
    return (
        <div className="page-container display-grid" >
            <div className='max-content'>
            <div >
            {dollarValueTier1 &&
            <>
                            <label htmlFor="cent">Dollar value for Patron Paid Member</label>
                            <input
                                type="number"
                                className="form-control my-2"
                                id="cent"
                                accept="number"
                                onChange={e => handleDollarTier1Change(e.target.value)}
                                value={dollarValueTier1}
                            />
                        <button type="submit" className="btn btn-primary" disabled={isLoading} onClick={updateDollarValueTier1}>
                            {isLoading ? 'Updating...' : 'Update Data'}
                        </button>
                </>
            }
        </div>
        <div >
            {dollarValueTier2 &&
            <>
                            <label htmlFor="cent">Dollar value for Patron Paid Member Plus</label>
                            <input
                                type="number"
                                className="form-control my-2"
                                id="cent"
                                accept="number"
                                onChange={e => handleDollarTier2Change(e.target.value)}
                                value={dollarValueTier2}
                            />
                        <button type="submit" className="btn btn-primary" disabled={isLoading} onClick={updateDollarValueTier2}>
                            {isLoading ? 'Updating...' : 'Update Data'}
                        </button>
                </>
            }
        </div>
        </div>
        </div>
    );
};

export default Miscellaneous;