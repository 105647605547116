import React, { useEffect, useState } from 'react';
import { ALL_IMAGES_API, DELETE_IMAGE_API, TOKEN_KEY } from '../config/Constants';
import { fetchImageUrl } from '../config/ImageUtils';
import { axiosInstance, setAuthToken } from './AxiosInstance';
import Loading from './Loading';
import { Card, Col, Container, Row } from 'react-bootstrap';

const ImageList = ({ setErrorMsg }) => {
    const [imageList, setImageList] = useState(null);
    const [imageLoading, setImageLoading] = useState(true);
    const [imageUrls, setImageUrls] = useState({});

    const deleteImage = (id) => {
        setAuthToken(localStorage.getItem(TOKEN_KEY));
        axiosInstance.delete(DELETE_IMAGE_API, {params: {imageId: id}})
        .then(resp => {
          const newImageList = imageList.filter(item => item.id !== id);
          delete imageUrls[id];
          setImageList(newImageList);
          setImageUrls(imageUrls);
        })
        .catch(e => setErrorMsg("Failed to delete image"));
      }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setAuthToken(localStorage.getItem(TOKEN_KEY));
                const imageResp = await axiosInstance.get(ALL_IMAGES_API);
                if (imageResp.status === 200) {
                    setImageList(imageResp.data);
                } else {
                    setErrorMsg(imageResp.data);
                }
            } catch (error) {
                setErrorMsg("Failed to load images");
            } finally {
                setImageLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchAllImageUrls = async () => {
            const newImageUrls = {};
            // Use Promise.all to await all fetchImageUrl calls
            await Promise.all(imageList.map(async (image) => {
                const url = await fetchImageUrl(image.id);
                newImageUrls[image.id] = url;
            }));
            setImageUrls({ ...newImageUrls }); // Update state with new or existing URLs
        };

        if (imageList != null) {
            fetchAllImageUrls();
        }
    }, [imageList]);

    if (imageLoading) return <Loading />; // Show loading indicator while fetching data

    return (
        <>
            <Row className="mb-4 bold-text">
                <Col md={1} sm={1}>ID</Col>
                <Col md={2} sm={2}>Creation Time</Col>
                <Col md={2} sm={2}>Image Name</Col>
                <Col md={7} sm={7}>Image</Col>
            </Row>
            {imageList.map((image, index) => (
                <Row key={index} className={index % 2 !== 0 ? 'striped-row-even my-4 py-2' : 'striped-row-odd my-4 py-2'}>
                    <Col md={1} sm={1}>
                        <Row className='justify-content-center'>{image.id}</Row>
                        <Row>
                            <a className='justify-content-center cursor-pointer' onClick={()=>{deleteImage(image.id)}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash mt-4" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                                <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                            </svg>
                            </a>
                        </Row>
                    </Col>
                    <Col md={2} sm={2}>{image.creationTime}</Col>
                    <Col md={2} sm={2}>{image.imageName}</Col>
                    <Col md={7} sm={7}>
                        <Card key={image.id}>
                            {imageUrls[image.id] ? (
                                <Card.Img variant="top" src={imageUrls[image.id].data} alt={`Image ${index}`} onContextMenu={(e) => e.preventDefault()} />
                            ) : (
                                <Loading width={"2em"} height={"2em"} />
                            )}
                        </Card>
                    </Col>
                </Row>
            ))}
        </>
    );
};

export default ImageList;