import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { setAuthToken, axiosInstance } from "./AxiosInstance";
import { LOGOUT_API, TOKEN_KEY } from "../config/Constants";
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './style/NavBar.css';
import { fetchWebsiteLogo } from "../config/ImageUtils";

const NavBar = ({ activePage, updateActivePage, setIsLoggedIn, user }) => {
  const navigate = useNavigate();
  const[logoImageSrc, setLogoImageSrc] = useState(null);

  const setLogo = async () => {
    const imageFetch = await fetchWebsiteLogo();
    if(imageFetch != null){
        setLogoImageSrc(imageFetch);
    }
  }
  useEffect(() => {
    setLogo();
  }, []);

  const handleLogout = async () => {
    try {
      setAuthToken(localStorage.getItem(TOKEN_KEY));
      await axiosInstance.post(LOGOUT_API);
    } catch (error) {
      // Handle error
    }
    console.log("logout called in navbar");
    localStorage.removeItem(TOKEN_KEY);
    setIsLoggedIn(false);
    navigate("/");
  };

  const handleLogin = () => {
    navigate("/");
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-black border-bottom border-body" id="appNavBar">
      <div className="container-fluid">
        <div className="navbar-brand logoImage" to="#">
            {logoImageSrc && <img src={logoImageSrc} alt="website logo" height={'32px'}/>}
        </div>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {/* <li className="nav-item">
              <Link className={`nav-link ${activePage === 0 ? 'active' : ''}`} to="/" onClick={() => updateActivePage(0)}>Main Page</Link>
            </li> */}
            {/* <li className="nav-item">
              <Link className={`nav-link ${activePage === 1 ? 'active' : ''}`} to="/" onClick={() => updateActivePage(1)}>Member Page</Link>
            </li> */}
            <li className="nav-item">
              <Link className={`nav-link ${activePage === 2 ? 'active' : ''}`} to="/" onClick={() => updateActivePage(2)}>Random Captions</Link>  {/* paid_member */}
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${activePage === 3 ? 'active' : ''}`} to="/" onClick={() => updateActivePage(3)}>Caption Stories</Link>  {/* paid_member_plus */}
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${activePage === 4 ? 'active' : ''}`} to="controllerApp" onClick={() => updateActivePage(4)}>Control App</Link>  {/* member*/}
            </li>
            {user && user.userRole && user.userRole === 'ADMIN' &&(
              <li className="nav-item">
                <Link className={`nav-link ${activePage === 100 ? 'active' : ''}`} to="/admin" onClick={() => updateActivePage(100)}>{user.userRole}</Link>
              </li>
            )}
          </ul>
          <div className="d-flex justify-content-center align-items-center">
            {user ? (
                <>
                    <span className="mx-2">{user.username}</span>
                    <button className="btn btn-outline-light" type="button" onClick={handleLogout}>Logout</button>
                </>
            ) : (
              <button className="btn btn-outline-light" type="button" onClick={handleLogin}>Login</button>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
