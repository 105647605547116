import React, { useState, useEffect } from "react";
import { STORY_PAGE, TOKEN_KEY } from "../config/Constants";
import Loading from "./Loading";
import { setAuthToken, axiosInstance } from "./AxiosInstance";
import { Container, Row, Col, Card, Collapse } from 'react-bootstrap';
import { fetchImageUrl } from "../config/ImageUtils";
import './style/StoryPage.css'; // Import custom CSS for additional styling

const StoryPage = ({ setErrorMsg, activePage }) => {
  const [storyData, setStoryData] = useState(null);
  const [storyLoading, setStoryLoading] = useState(true);
  const [imageUrls, setImageUrls] = useState({});
  const [openStory, setOpenStory] = useState(null); // To track the open story

  useEffect(() => {
    const fetchData = async () => {
      try {
        setAuthToken(localStorage.getItem(TOKEN_KEY));
        const storyResp = await axiosInstance.get(STORY_PAGE, { params: { page: activePage } });
        if (storyResp.status === 200) {
          setStoryData(storyResp.data);
        } else {
          setErrorMsg(storyResp.data);
        }
      } catch (error) {
        if (error.response.status === 403) {
          setErrorMsg("You don't have access to this page");
        } else if (error.response.status === 423) {
          setErrorMsg(error.response.data);
        } else {
          setErrorMsg("Failed to load the page");
        }
      } finally {
        setStoryLoading(false);
      }
    };
    fetchData();
  }, [activePage]);


  const fetchAllImageUrls = async () => {
    try {
      // Create an array of promises for all the image URL fetches
      const fetchPromises = [];
  
      for (const story of storyData) {
        for (const imageId of story.imageIdSet) {
          // Push each fetchImageUrl promise into the array
          fetchPromises.push(fetchImageUrl(imageId).then(url => ({ imageId, url })));
        }
      }
  
      // Wait for all promises to resolve using Promise.all
      const results = await Promise.all(fetchPromises);
  
      // Create a new object to store the image URLs
      const newImageUrls = {};
      results.forEach(({ imageId, url }) => {
        newImageUrls[imageId] = url;
      });
  
      // Update state with all URLs
      setImageUrls(newImageUrls);
    } catch (error) {
      console.error("Error fetching image URLs:", error);
    }
  };

  useEffect(() => {
    if (storyData) {
      fetchAllImageUrls();
    }
  }, [storyData]);

  if (storyLoading) return <Loading />;

  const handleToggleStory = (index) => {
    setOpenStory(openStory === index ? null : index); // Toggle the open story
  };

  return (
    <Container fluid className="page-container">
  {storyData && storyData.map((story, index) => (
    <Row key={index} className="story-row mb-4">
      <Col md={12}>
        <Card className="story-card">
          <Card.Header
            onClick={() => handleToggleStory(index)}
            aria-controls={`story-collapse-${index}`}
            aria-expanded={openStory === index}
            className="story-heading"
          >
            <h2 style={{ cursor: "pointer", margin: 0 }}>{story.heading}</h2>
          </Card.Header>
          <Collapse in={openStory === index}>
            <Card.Body id={`story-collapse-${index}`}>
              <Row className="mt-3">
                {/* Images block */}
                <Col md={12} className="story-images">
                  <Row>
                    {story.imageIdSet
                      .filter((imageId) => imageUrls[imageId])  // Filter out null or undefined image URLs
                      .map((imageId) => ({ imageId, fileName: imageUrls[imageId]?.fileName }))
                      .sort((a, b) => a.fileName.localeCompare(b.fileName))
                      .map(({ imageId }, idx) => (
                        <Col key={idx} xs={12} className="mb-3">
                          <Card className="image-card">
                            {imageUrls[imageId] ? (
                              <Card.Img
                                variant="top"
                                src={imageUrls[imageId].data}
                                alt={`Image ${index}`}
                                onContextMenu={(e) => e.preventDefault()}
                                className="story-image"
                              />
                            ) : (
                              <Loading width={"2em"} height={"2em"} />
                            )}
                          </Card>
                        </Col>
                      ))}
                  </Row>
                </Col>

                {/* Story Asset button */}
                <Col md={12} className="mt-3">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => window.location.href = story.assetUrl}
                  >
                    Story Asset
                  </button>
                </Col>
              </Row>
            </Card.Body>
          </Collapse>
        </Card>
      </Col>
    </Row>
  ))}
</Container>

  );
};

export default StoryPage;