import React, { useState, useEffect } from 'react';
import PostStory from './PostStory'; // Import your PostStory component
import { ALL_STORIES, IS_ADMIN_API, TOKEN_KEY } from '../config/Constants';
import { axiosInstance, setAuthToken } from './AxiosInstance';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import StoriesTable from './StoriesTable';
import ImageList from './ImageList';
import TokenInfo from './TokenInfo';
import AllUsers from './AllUsers';
import './style/AdminPage.css';
import AddImage from './AddImage';
import Miscellaneous from './Miscellaneous';

const AdminPage = ({setErrorMsg}) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // Fetch stories on initial load
  useEffect(() => {
    const fetchStories = async () => {
      setIsLoading(true);

      try {
        setAuthToken(localStorage.getItem(TOKEN_KEY));
        const response = await axiosInstance.get(IS_ADMIN_API);
        if(response.status != 200){
            setErrorMsg("Not Authorized for Admin Page");
            navigate("/");
        }
      } catch (error) {
        setErrorMsg("Not Authorized for Admin Page");
        navigate("/");
      } finally {
        setIsLoading(false);
      }
    };

    fetchStories();
  }, []);

  return (
    <div className='admin-page' >
            <div className="d-flex align-items-center justify-content-center admin-nav-bar">
                <Link className="btn btn-primary mx-1" to={"/admin/addStory"} >Add Story</Link>
                <Link className="btn btn-primary mx-1" to={"/admin/allStories"} >Show All Stories</Link>
                <Link className="btn btn-primary mx-1" to={"/admin/addImage"} >Add Image</Link>
                <Link className="btn btn-primary mx-1" to={"/admin/allImages"} >Show All Images</Link>
                <Link className="btn btn-primary mx-1" to={"/admin/tokenInfo"} >Token Info</Link>
                <Link className="btn btn-primary mx-1" to={"/admin/allUsers"} >All Users</Link>
                <Link className="btn btn-primary mx-1" to={"/admin/misc"} >Miscellaneous </Link>
            </div>
            <div className='page-container'>
            <Routes>
                <Route path="/addStory" element={<PostStory setErrorMsg={setErrorMsg} />} />
                <Route path="/allStories" element={<StoriesTable setErrorMsg={setErrorMsg} />} />
                <Route path="/allImages" element={<ImageList setErrorMsg={setErrorMsg} />} />
                <Route path="/addImage" element={<AddImage setErrorMsg={setErrorMsg} />} />
                <Route path="/tokenInfo" element={<TokenInfo setErrorMsg={setErrorMsg} />} />
                <Route path="/allUsers" element={<AllUsers setErrorMsg={setErrorMsg} />} />
                <Route path="/misc" element={<Miscellaneous setErrorMsg={setErrorMsg} />} />
            </Routes>
            </div>
    </div>
  );
};

export default AdminPage;