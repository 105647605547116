import React, { useEffect, useState, useRef } from 'react';
import { axiosInstance, setAuthToken } from './AxiosInstance';
import { CONTROLLER_APP_PAGE, TOKEN_KEY } from '../config/Constants';
import Loading from './Loading';
import MaintenancePage from './MaintenancePage';

const ControllerApp = ({ setErrorMsg }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [popupBlocked, setPopupBlocked] = useState(false);
    const [isControllerFetchFailed, setIsControllerFetchFailed] = useState(false);
    const [isHtmlFetched, setIsHtmlFetched] = useState(false);
    

    const getHtmlPage = async () => {
        if(isHtmlFetched) return;
        setErrorMsg(null);
        setPopupBlocked(false);
        setIsControllerFetchFailed(false);

        try {
            setAuthToken(localStorage.getItem(TOKEN_KEY));
            const htmlPageResp = await axiosInstance.get(CONTROLLER_APP_PAGE, {
                headers: {
                    'Content-Type': 'text/html'
                }
            });
            setIsHtmlFetched(true);
            const blob = new Blob([htmlPageResp.data], { type: 'text/html' });
            const url = URL.createObjectURL(blob);
            const popup = window.open(url, '_blank');

            if (!popup || popup === null) {
                setPopupBlocked(true);
            }
        } catch (error) {
            setIsControllerFetchFailed(true);
            if (error.response && error.response.status === 423) {
                setErrorMsg(error.response.data);
            } else if (error.response && error.response.status === 403) {
                setErrorMsg('You are not authorized for this gaming controller');
            } else {
                setErrorMsg('Error loading game page');
            }
        } finally {
            setIsLoading(false);
        }
    };

    // Call getHtmlPage only once when the component mounts
    useEffect(() => {
        getHtmlPage();
    }, []); // Empty dependency array ensures this runs only once

    if (isLoading) return <Loading />;

    if (isControllerFetchFailed) return <MaintenancePage />;

    return (
        <>
            {popupBlocked && (
                <div className="alert">
                    <p>It looks like popups are blocked in your browser. Please enable popups for this site to proceed.</p>
                </div>
            )}
        </>
    );
};

export default ControllerApp;