import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CHANGE_PASSWORD_API, SEND_OTP_PASSWORD_RESET_API } from "../config/Constants";
import 'bootstrap/dist/css/bootstrap.min.css';

const ForgotPassword = ({ setErrorMsg }) => {
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [otpInput, setOtpInput] = useState("");
  const [otpTimer, setOtpTimer] = useState(300); // Timer in seconds (5 minutes)
  const [otpExpired, setOtpExpired] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const navigate = useNavigate();

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  useEffect(() => {
    if (isOtpSent) {
      startOtpTimer();
    }
  }, [isOtpSent]);

  useEffect(() => {
    if (otpTimer === 0) {
      setOtpExpired(true);
    }
  }, [otpTimer]);

  const startOtpTimer = () => {
    setOtpExpired(false);
    setOtpTimer(300); // Reset timer to 5 minutes
    const interval = setInterval(() => {
      setOtpTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    // Clean up the interval when component unmounts or when OTP timer expires
    return () => clearInterval(interval);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // Check if passwords match
    if (password === confirmPassword) {
      setPasswordMatch(true);

      try {
        const postData = { username: username, otp:otpInput, password: password, confirmPassword: confirmPassword };
        const resetPasswordResp = await axios.post(CHANGE_PASSWORD_API, postData); // Replace with your actual endpoint

        if (resetPasswordResp.status === 200) {
          setErrorMsg(null);
          navigate('/'); // Redirect to login page after successful password reset
        } else {
          setErrorMsg(resetPasswordResp.data);
        }
      } catch (error) {
        console.log(error);
        setErrorMsg("Failed to reset password, please try again later.");
      }
    } else {
      setPasswordMatch(false);
      setErrorMsg("Password and Confirm Password do not match");
    }
  };

  const handleSendOtp = async () => {
    if (!validateEmail(username)) {
      setErrorMsg("Please enter a valid email address");
      return;
    }

    try {
      const sendOtpResp = await axios.post(SEND_OTP_PASSWORD_RESET_API, username, {
        headers: {
          'Content-Type': 'text/plain' // Specify the content type as plain text
        }}); // Replace with your actual endpoint

      if (sendOtpResp.status === 200) {
        console.log("OTP sent successfully");
        setIsOtpSent(true); // Show OTP input
      } else {
        setErrorMsg("Failed to send OTP, please try again later.");
      }
    } catch (error) {
      if (error.response.status === 423) {
        setErrorMsg(error.response.data);
      } else {
        setErrorMsg("Failed to send OTP, please try again later.");
      }
    }
  };

  return (
          
            <div className="page-container display-grid" >
            <div className="max-content" >
              <h3 className="card-title text-center">Forgot Password</h3>
              <p>Please enter your email, OTP, and new password.</p>

              <form onSubmit={handleFormSubmit}>
                {!isOtpSent ? (
                  <div>
                    <div className="form-group text-start">
                      <label htmlFor="username">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        id="username"
                        placeholder="Enter email"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                        readOnly={isOtpSent} // Make email field read-only after OTP is sent
                      />
                      {!validateEmail(username) && username.length > 0 && (
                        <p className="text-danger">Please enter a valid email address</p>
                      )}
                    </div>
                    <button type="button" className="btn btn-primary btn-block mt-3" onClick={handleSendOtp}>
                      Send OTP
                    </button>
                  </div>
                ) : (
                  <div>
                    <div className="form-group text-start">
                      <label htmlFor="otpInput">Enter OTP (You have {Math.floor(otpTimer / 60)}:{otpTimer % 60} minutes)</label>
                      <input
                        type="text"
                        className="form-control"
                        id="otpInput"
                        placeholder="Enter OTP"
                        value={otpInput}
                        onChange={(e) => setOtpInput(e.target.value)}
                        maxLength={6}
                        required
                      />
                      {otpExpired && (
                        <p className="text-danger">OTP expired. Please request a new one.</p>
                      )}
                    </div>

                    <div className="form-group text-start">
                      <label htmlFor="password">New Password</label>
                      <div className="input-group">
                        <input
                          type={showPassword1 ? "text" : "password"}
                          className="form-control"
                          id="password"
                          placeholder="New Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            {showPassword1 ? (
                              <FaEye onClick={() => setShowPassword1(!showPassword1)} />
                            ) : (
                              <FaEyeSlash onClick={() => setShowPassword1(!showPassword1)} />
                            )}
                          </span>
                        </div>
                      </div>
                      {password.length > 0 && password.length < 8 && (
                        <p className="text-danger">Password must be at least 8 characters</p>
                      )}
                    </div>

                    <div className="form-group text-start">
                      <label htmlFor="confirmPassword">Confirm Password</label>
                      <div className="input-group">
                        <input
                          type={showPassword2 ? "text" : "password"}
                          className="form-control"
                          id="confirmPassword"
                          placeholder="Confirm Password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          required
                          style={{ borderColor: passwordMatch ? "#ced4da" : "red" }}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            {showPassword2 ? (
                              <FaEye onClick={() => setShowPassword2(!showPassword2)} />
                            ) : (
                              <FaEyeSlash onClick={() => setShowPassword2(!showPassword2)} />
                            )}
                          </span>
                        </div>
                      </div>
                      {!passwordMatch && (
                        <p className="text-danger">Password and Confirm Password do not match</p>
                      )}
                    </div>

                    <button type="submit" className="btn btn-primary btn-block mt-3">Reset Password</button>
                  </div>
                )}
              </form>
            </div>
          </div>
  );
};

export default ForgotPassword;