import React from "react";
import { useEffect } from "react";

const Notification = ({errorMsg, setErrorMsg}) => {

    useEffect(() => {
        // After 5 seconds, hide the notification by setting errorMsg to null
        const timer = setTimeout(() => {
            setErrorMsg(null);
        }, 10000);

        // Cleanup function to clear the timer if component unmounts or errorMsg changes
        return () => clearTimeout(timer);
    }, [errorMsg]);

    if(errorMsg == null) return;
    if(errorMsg.length === 0) return;
    return (
        <div style={{zIndex: 999}} className="alert alert-danger d-flex align-items-center position-fixed top-0 start-50 translate-middle-x z-index-3" role="alert">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
            </svg>
            <div style={{paddingRight: '5px'}}>
                {errorMsg}
            </div>
            <button type="button" className="btn-close" onClick={() => setErrorMsg(null)} aria-label="Close"></button>
        </div>
    );
};

export default Notification;