import React, { useState, useEffect } from 'react';
import './App.css'; // Assuming you have CSS for styling
import Notification from './components/Notification';
import { IS_SERVER_UP, CURRENT_USER, TOKEN_KEY, CHECK_TOKEN_EXIST_INTERVAL, GET_WEBSITE_BACKGROUND_API } from './config/Constants';
import axios from 'axios';
import MaintenancePage from './components/MaintenancePage';
import Loading from './components/Loading';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import StoryPage from './components/StoryPage';
import NavBar from './components/NavBar';
import { axiosInstance, setAuthToken } from './components/AxiosInstance';
import Register from './components/Register';
import AdminPage from './components/AdminPage';
import ForgotPassword from './components/ForgotPassword';
import ControllerApp from './components/ControllerApp';
import Login from './components/Login';

const App = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [serverError, setServerError] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);
    const [activePage, setActivePage] = useState(null);

    setInterval(() => {
        if(localStorage.getItem(TOKEN_KEY) == null){
            setIsLoggedIn(false);
        }
    }, CHECK_TOKEN_EXIST_INTERVAL);

    const fetchData = async () => {
        try {
            await axios.get(IS_SERVER_UP);
        } catch (error) {
            setServerError(error);
        } finally {
            setLoading(false);
        }
    };

    const setVaribales = async () => {
        document.documentElement.style.setProperty('--website-bg-link', `url("${GET_WEBSITE_BACKGROUND_API}")`);
    }
    useEffect(() => {
        setVaribales();
        fetchData();
    }, []);

    useEffect(() => {
        const fetchUser = async () => {
            try{
                setAuthToken(localStorage.getItem(TOKEN_KEY));
                const currentUserResp = await axiosInstance.get(CURRENT_USER);
                if(currentUserResp.status === 200){
                    setErrorMsg(null);
                    setUser(currentUserResp.data);
                    setIsLoggedIn(true);
                } else {
                    setErrorMsg(currentUserResp.data);
                    setUser(null);
                    setIsLoggedIn(false);
                    setActivePage(null);
                }
            } catch(error){
                setErrorMsg(error.data);
                setUser(null);
                setIsLoggedIn(false);
                setActivePage(null);
            }
        }
        fetchUser();
    }, [isLoggedIn]);

    if (loading) return <Loading />;
    if (serverError) return <MaintenancePage />;
  
    return (
        <Router>
            <div className="App">
                <NavBar activePage={activePage} updateActivePage={setActivePage} user={user} setIsLoggedIn={setIsLoggedIn}/>
                <Notification errorMsg={errorMsg} setErrorMsg={setErrorMsg} />
                <Routes >
                    <Route path="/" element={isLoggedIn ? 
                                                            <StoryPage setErrorMsg={setErrorMsg} activePage={activePage} />
                                                            : <Login setIsLoggedIn={setIsLoggedIn}  setErrorMsg={setErrorMsg}/>}
                    />
                    <Route path="/admin/*" element={isLoggedIn ?
                                                                <AdminPage setErrorMsg={setErrorMsg}/>
                                                                : <Login setIsLoggedIn={setIsLoggedIn}  setErrorMsg={setErrorMsg}/>
                    } />
                    <Route path="/controllerApp" element={isLoggedIn ?
                                                                    <ControllerApp setErrorMsg={setErrorMsg}/>
                                                                    : <Login setIsLoggedIn={setIsLoggedIn}  setErrorMsg={setErrorMsg}/>
                    } />
                    <Route path="/register" element={<Register setErrorMsg={setErrorMsg} />} />
                    <Route path="/forgot-password" element={<ForgotPassword setErrorMsg={setErrorMsg} />} />
                </Routes>
            </div>
        </Router>
    );
};

export default App;