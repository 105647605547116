import React, { useState, useEffect } from "react";
import {ALL_USERS, AVAILABLE_ROLES, BLOCK_USER_API, SET_USER_ROLE, STORY_PAGE, TOKEN_KEY, UNBLOCK_USER_API, UPDATE_USER_ROLE_FROM_PATREON } from "../config/Constants";
import Loading from "./Loading";
import { setAuthToken, axiosInstance } from "./AxiosInstance";
import { Container, Row, Col} from 'react-bootstrap';
import { DateUtils } from "../config/DateUtils";

const AllUsers = ({setErrorMsg}) => {
    const [allUsersData, setAllUsersData] = useState(null);
    const [totalUsersCount, setTotalUsersCount] = useState(-1);
    const [availableRoles, setAvailableRoles] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const fetchAllUsers = async () => {
        setIsLoading(true);
        try{
            setAuthToken(localStorage.getItem(TOKEN_KEY));
            const respData = await axiosInstance.get(ALL_USERS);
            setTotalUsersCount(respData.data.total);
            setAllUsersData(respData.data.users);
        } catch (error) {
            setErrorMsg("Failed to get all users of the app");
        } finally {
            setIsLoading(false);
        }
    };

    const fetchAvailableRoles = async () => {
        try{
            setAuthToken(localStorage.getItem(TOKEN_KEY));
            const respData = await axiosInstance.get(AVAILABLE_ROLES);
            setAvailableRoles(respData.data);
        }catch(error){
            setErrorMsg("Error getting available roles options");
        }
    }
    useEffect(() => {
        fetchAllUsers();
        fetchAvailableRoles();
    }, []);

    const blockUser = async (username) => {
        try{
            setAuthToken(localStorage.getItem(TOKEN_KEY));
            const respData = await axiosInstance.post(BLOCK_USER_API, username, {headers: {
                'Content-Type': 'text/plain', // Set the Content-Type to text/plain
            }});
            fetchAllUsers();
        } catch (error) {
            if (error.response.status === 403) {
                setErrorMsg("Not authorized to block this user");
            } else {
                setErrorMsg("Failed to block user");
            }
        }
    }

    const unblockUser = async (username) => {
        try{
            setAuthToken(localStorage.getItem(TOKEN_KEY));
            const respData = await axiosInstance.post(UNBLOCK_USER_API, username, {headers: {
                'Content-Type': 'text/plain', // Set the Content-Type to text/plain
              }});
              fetchAllUsers();
        } catch (error) {
            setErrorMsg("Failed to unblock user");
        }
    }

    const setUserRole = async (username, role) => {
        try{
            setAuthToken(localStorage.getItem(TOKEN_KEY));
            const respData = await axiosInstance.patch(SET_USER_ROLE, {username: username, userRole: role});
            fetchAllUsers();
        } catch (error) {
            if (error.response.status === 403) {
                setErrorMsg("Not authorized to update user role for this user");
            } else {
                setErrorMsg("Failed to update user role");
            }
        }
    }

    const updateUserRoleAuto = async () => {
        setIsLoading(true);
        try{
            setAuthToken(localStorage.getItem(TOKEN_KEY));
            const respData = await axiosInstance.post(UPDATE_USER_ROLE_FROM_PATREON);
        } catch (error) {
            setErrorMsg("Failed to update user roles from patron API");
        } finally {
            fetchAllUsers();
        }
    }

    if(isLoading) return <Loading />

    return (
        <>
            <Row>
                <Col className="d-flex justify-content-start" md={6} sm={6}>Total Users: {totalUsersCount}</Col>
                <Col className="d-flex justify-content-end" md={6} sm={6}>
                    <button className="btn btn-primary" onClick={() => updateUserRoleAuto()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className="bi bi-bootstrap-reboot ms-2" viewBox="0 0 16 16">
                        <path d="M1.161 8a6.84 6.84 0 1 0 6.842-6.84.58.58 0 1 1 0-1.16 8 8 0 1 1-6.556 3.412l-.663-.577a.58.58 0 0 1 .227-.997l2.52-.69a.58.58 0 0 1 .728.633l-.332 2.592a.58.58 0 0 1-.956.364l-.643-.56A6.8 6.8 0 0 0 1.16 8z"/>
                        <path d="M6.641 11.671V8.843h1.57l1.498 2.828h1.314L9.377 8.665c.897-.3 1.427-1.106 1.427-2.1 0-1.37-.943-2.246-2.456-2.246H5.5v7.352zm0-3.75V5.277h1.57c.881 0 1.416.499 1.416 1.32 0 .84-.504 1.324-1.386 1.324z"/>
                        </svg>
                        Update Roles
                    </button>
                </Col>
            </Row>
            <Row style={{text: 'bold', wordWrap: 'break-word'}} key='-1' className="bold-text mt-4">
                <Col md={1} sm={1} >ID</Col>
                <Col md={3} sm={3} >USERNAME</Col>
                <Col md={1} sm={1} >USER ROLE</Col>
                <Col md={1} sm={1} >Account Unlocked</Col>
                <Col md={1} sm={1} >Account Active</Col>
                <Col md={1} sm={1} >Account Enabled</Col>
                <Col md={2} sm={2} >Creation Time</Col>
                <Col md={2} sm={2} >Update Time</Col>
            </Row>
            {
                allUsersData.map((user, index) => (
                    <Row key={index} className={index % 2 !== 0 ? 'striped-row-even my-4 py-2' : 'striped-row-odd my-4 py-2'}>
                        <Col md={1} sm={1} >
                            <Row className="justify-content-center">{user.id}</Row>
                            <Row className="justify-content-center">
                                {
                                    (user.accountNonLocked && user.accountNonExpired)
                                        ? <button className="btn btn-danger" onClick={() => blockUser(user.username)}>Block</button>
                                        : <button className="btn btn-success" onClick={() => unblockUser(user.username)}>Unblock</button>
                                }
                            </Row>
                        </Col>
                        <Col md={3} sm={3} >{user.username}</Col>
                        <Col md={1} sm={1} >
                            <select value={user.userRole} onChange={(e) => {setUserRole(user.username, e.target.value)}} className="form-control" required>
                                {availableRoles &&
                                    availableRoles.map((role) => <option key={role} value={role}>{role}</option>)
                                }
                            </select>
                        </Col>
                        <Col md={1} sm={1} >{user.accountNonLocked ? 'true' : 'false'}</Col>
                        <Col md={1} sm={1} >{user.accountNonExpired ? 'true' : 'false'}</Col>
                        <Col md={1} sm={1} >{user.enabled ? 'true' : 'false'}</Col>
                        <Col md={2} sm={2} >{DateUtils.formatDateTime(user.creationTime)}</Col>
                        <Col md={2} sm={2} >{DateUtils.formatDateTime(user.updateTime)}</Col>
                    </Row>
                ))
            }
        </>
    );
}

export default AllUsers;