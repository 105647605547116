import axios from "axios";
import { REGISTER_API, ACTIVATE_REGISTERED_USER, RESEND_OTP_API } from "../config/Constants";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

const Register = ({ setErrorMsg }) => {
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true); // State to track password match
  const [isRegistered, setIsRegistered] = useState(false);
  const [otpInput, setOtpInput] = useState(""); // State for OTP input
  const [otpTimer, setOtpTimer] = useState(300); // Timer in seconds (5 minutes)
  const [otpExpired, setOtpExpired] = useState(false); // Flag to track if OTP timer has expired
  const navigate = useNavigate();

  const validateEmail = (email) => {
    // Basic email validation regex
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleOtpSubmit = async (event) => {
    event.preventDefault();

    if (otpInput.length === 6) {
      try {
        const postData = { username: username, otp: otpInput };
        const activateUserRegisteredResp = await axios.post(ACTIVATE_REGISTERED_USER, postData);
        if (activateUserRegisteredResp.status === 202) { // 202 means accepted
          console.log("OTP successfully verified");
          navigate('/'); // route to main page
        } else {
          console.log("OTP verification failed");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (isRegistered) {
      startOtpTimer();
    }
  }, [isRegistered]);

  useEffect(() => {
    if (otpTimer === 0) {
      setOtpExpired(true);
    }
  }, [otpTimer]);

  const startOtpTimer = () => {
    setOtpExpired(false);
    setOtpTimer(300); // Reset timer to 5 minutes
    const interval = setInterval(() => {
      setOtpTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    // Clean up the interval when component unmounts or when OTP timer expires
    return () => clearInterval(interval);
  };

  const handleResendOtp = async () => {
    try {
      const resendOtpResp = await axios.post(RESEND_OTP_API, username, {
        headers: {
          'Content-Type': 'text/plain' // Specify the content type as plain text
        }
      }); // Ensure you have an endpoint for resending OTP
      if (resendOtpResp.status === 200) {
        console.log("OTP resent successfully");
        startOtpTimer(); // Restart the OTP timer
      } else {
        console.log("Failed to resend OTP");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // Check if passwords match
    if (password === confirmPassword) {
      // Reset passwordMatch state
      setPasswordMatch(true);

      try {
        const postData = { username: username, password: password, confirmPassword: confirmPassword };
        const registerResp = await axios.post(REGISTER_API, postData);

        if (registerResp.status === 200) {
          setErrorMsg(null);
          setIsRegistered(true); // Example: Set registration success state
        } else {
          setErrorMsg(registerResp.data);
        }
      } catch (error) {
        console.log(error);
        setErrorMsg("Failed to register user, please try again later.");
      }
    } else {
      // Set passwordMatch state to false
      setPasswordMatch(false);
      setErrorMsg("Password and Confirm Password do not match");
    }
  };

  return (
          <div className="page-container display-grid" >
            <div className="max-content" >
              <h3 className="card-title text-center">Register</h3>
              <p>Please enter your details</p>
              <form onSubmit={handleFormSubmit}>
                <div className="form-group text-start">
                  <label htmlFor="username" >Username</label>
                  <input
                    type="email"
                    className="form-control"
                    id="username"
                    placeholder="Enter email"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                  />
                  {!validateEmail(username) && username.length > 0 && (
                    <p className="text-danger">Please enter a valid email address</p>
                  )}
                </div>

                <div className="form-group text-start">
                  <label htmlFor="password">Password</label>
                  <div className="input-group">
                    <input
                      type={showPassword1 ? "text" : "password"}
                      className="form-control"
                      id="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <div className="input-group-append">
                      <span className="input-group-text h-100">
                        {showPassword1 ? (
                          <FaEye onClick={() => setShowPassword1(!showPassword1)} />
                        ) : (
                          <FaEyeSlash onClick={() => setShowPassword1(!showPassword1)} />
                        )}
                      </span>
                    </div>                    
                  </div>
                    {password.length > 0 && password.length < 8 && (
                      <p className="text-danger">Password must be min 8 characters</p>
                    )}
                </div>

                <div className="form-group text-start">
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <div className="input-group">
                    <input
                      type={showPassword2 ? "text" : "password"}
                      className="form-control"
                      id="confirmPassword"
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                      style={{ borderColor: passwordMatch ? "#ced4da" : "red" }} // Highlight in red when passwords don't match
                    />
                    <div className="input-group-append">
                      <span className="input-group-text h-100">
                        {showPassword2 ? (
                          <FaEye onClick={() => setShowPassword2(!showPassword2)} />
                        ) : (
                          <FaEyeSlash onClick={() => setShowPassword2(!showPassword2)} />
                        )}
                      </span>
                    </div>
                  </div>
                {!passwordMatch || (confirmPassword.length > 0 && confirmPassword !== password) && (
                  <p className="text-danger">Password and Confirm Password do not match</p>
                )}
                </div>

                {isRegistered && !otpExpired && (
                  <div className="form-group">
                    <label htmlFor="otpInput">Enter OTP (You have {otpTimer} seconds)</label>
                    <input
                      type="text"
                      className="form-control"
                      id="otpInput"
                      placeholder="Enter OTP"
                      value={otpInput}
                      onChange={(e) => setOtpInput(e.target.value)}
                      maxLength={6} // Assuming OTP is 6 digits
                      required
                    />
                    <button className="btn btn-primary mt-2" type="button" onClick={handleOtpSubmit}>
                      Submit OTP
                    </button>
                    <button className="btn btn-secondary mt-2" type="button" onClick={handleResendOtp}>
                      Resend OTP
                    </button>
                  </div>
                )}

                {isRegistered && otpExpired && (
                  <div>
                    <p className="text-danger">OTP expired. Please request a new one.</p>
                    <button className="btn btn-secondary mt-2" type="button" onClick={handleResendOtp}>
                      Resend OTP
                    </button>
                  </div>
                )}

                <button type="submit" className="btn btn-primary btn-block mt-3">Register</button>
              </form>
            </div>
          </div>
  );
};

export default Register;